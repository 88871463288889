import './App.css';
import MainRoutes from './pages/Routes/Routes';

function App() {
  //disabling consoles
  console.log = () => { };
  return (
    <MainRoutes />
  );
}

export default App;
